<template>
  <step-wrapper title="Сводная таблица" class="consolidated-report-step7">
    <base-list-view
      v-if="dataSource.SettingsContent"
      :dataSource="dataSource.SettingsContent.Settings"
      :headers="headers"
      hide-default-footer
      :showSettings="false"
      notShowSelect
      @onRefresh="actual"
      :notShowActions="['add', 'delete', 'refresh']"
      @customHandle="(e) => (editItem = e)"
      not-filter
    >
      <template slot="item.IncludeReport" slot-scope="scope">
        <div class="d-flex align-center">
          <div style="width: 38px">
            <v-checkbox
              v-if="scope.item.IncludeReport !== null"
              v-model="scope.item.IncludeReport"
              @click.stop
              class="mt-0 pt-0"
              hide-details
            />
          </div>
          <v-btn @click.stop="refreshItem(scope.item)" icon title="Обновить">
            <m-icon icon="tool-refresh" s16></m-icon>
          </v-btn>
        </div>
      </template>
    </base-list-view>

    <edit-menu :item.sync="editItem" @save="save"></edit-menu>
  </step-wrapper>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";

import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "@/layouts/BaseListView.vue";

import editMenu from "./editMenu";

export default {
  name: "consolidated-report-step7",
  components: {
    stepWrapper,
    BaseListView,

    editMenu,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step7,
      getDefaultObject: defaultSummaryReport.step7(),
      editItem: null,
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {},

      headers: [
        {
          text: "Мероприятия внутреннего контроля качества и безопасности медицинской деятельности (согласно п.9 раздела II приказа МЗ РФ от 31.07.2020 N 785н)",
          value: "Name",
        },
        {
          text: "Результаты внутреннего контроля качества и безопасности медицинской деятельности",
          value: "ResultText",
        },
        { text: "Выявленные несоответствия", value: "ViolationsText" },
        { text: "Перечень корректирующих мер", value: "TasksText" },
        { text: "Сведения о выполнении", value: "ExecutionText" },
        { text: "Отразить в приложении ", value: "IncludeReport" },
      ],
    };
  },
  computed: {},
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediatle: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async refreshItem(item) {
      this.loading = true;
      const { data } = await this.apiService.updateSummaryReportSettings(
        this.dataSource.Id,
        item.Id
      );
      this.$set(this.dataSource.SettingsContent.Settings, data.Id, data);
      this.$set(this.originObject.SettingsContent.Settings, data.Id, data);
      this.loading = false;
    },
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    actual() {
      this.init({
        query: {
          actual: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.consolidated-report-step7 {
  table thead tr th {
    white-space: unset;
  }
  td {
    white-space: pre-wrap;
  }
}
</style>
